<div class="flex items-center justify-between">
  <label class="block text-base font-medium leading-6 text-gray-900">{{ titleInputFile }}</label>
  @if (!displayUpload && filesSaved.length > 0 && files.length === 0) {
    <button (click)="displayUpload = true">
      <span class="material-icons ic-icon md-32"> cloud_upload </span>
    </button>
  }
</div>

@if ((files.length === 0 && filesSaved.length === 0) || displayUpload) {
  <div class="col-span-full" icFileDragNDrop (filesChangeEmiter)="onFileChange($event)">
    <div class="mx-auto max-w-xs">
      <label
        class="flex w-full cursor-pointer appearance-none items-center justify-center rounded-md border-2 border-dashed border-gray-200 p-6 transition-all hover:border-ica-primary">
        <div class="space-y-1 text-center">
          <div
            class="mx-auto inline-flex h-12 w-12 animate-bounce items-center justify-center rounded-full bg-gray-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-8 w-8 text-gray-500">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
            </svg>
          </div>
          <div class="text-gray-600">
            <span class="text-base font-medium">Haga clic para cargar o arrastrar y soltar</span>
          </div>
        </div>
        <input
          id="example5"
          type="file"
          class="sr-only"
          [accept]="aceptedFileTypes"
          [multiple]="multipleFile"
          (change)="onFileChange($any($event.target).files)" />
      </label>
    </div>
  </div>
}
@if (files.length > 0) {
  <div class="my-2 grid grid-cols-1">
    <mat-divider></mat-divider>
    @for (item of files; track item) {
      <div class="flex flex-col py-1 text-sm leading-6">
        <div class="flex items-center justify-between px-1">
          <span class="truncate font-medium">{{ item.name }}</span>
          <button class="ml-2" (click)="removeFile(item)">
            <span class="material-icons ic-icon-red md-24"> delete </span>
          </button>
        </div>
      </div>
    }
    @if (handler === 'upload') {
      <div class="my-4 text-center">
        <button class="ic-btn-primary" (click)="uploadFiles()" type="button">
          <mat-icon>upload</mat-icon>
          <span>{{ textBtnSave }}</span>
        </button>
      </div>
    }
    <mat-divider></mat-divider>
  </div>
}
@if (filesSaved.length > 0) {
  <div class="px-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
    <dt class="text-sm font-medium leading-6 text-gray-900">Archivos guardados:</dt>
    <dd class="text-sm text-gray-900 sm:col-span-2 sm:mt-0">
      <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200">
        @for (item of filesSaved; track item) {
          <li class="py1- flex items-center justify-between pl-4 pr-5 text-sm leading-6">
            <div class="flex w-0 flex-1 items-center">
              <svg
                class="h-5 w-5 flex-shrink-0 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true">
                <path
                  fill-rule="evenodd"
                  d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                  clip-rule="evenodd" />
              </svg>
              <div class="ml-4 flex min-w-0 flex-1 gap-2">
                <span class="truncate font-medium">{{ item.nombre ?? item.url }}</span>
              </div>
            </div>
            <div class="ml-4 flex-shrink-0">
              <a [href]="item.url" class="font-medium text-ica-primary hover:text-ica-cars-offers" target="_blank">
                <span class="material-icons ic-icon md-32"> download </span>
              </a>
              <button
                type="button"
                class="font-medium text-ica-primary hover:text-ica-cars-offers"
                (click)="eventViewImg(item.url)">
                <span class="material-icons ic-icon md-32"> visibility </span>
              </button>
            </div>
          </li>
        }
      </ul>
    </dd>
  </div>
}
